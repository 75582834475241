import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import Footer from 'components/Footer'
import PortfolioHeader from 'components/PortfolioHeader'

import BackgroundSection from 'components/BackgroundSection'

const PbjLive = () => {
  const {
    desktopHome,
    app,
    liveBlock,
    liveStream,
    pbjSchema,
    creatively,
    spiritune,
    moeAssist,
    liveApp1,
    liveApp2,
    liveApp3,
    headerImage,
  } = useStaticQuery(
    graphql`
      query {
        desktopHome: file(relativePath: { eq: "pbj-live/desktop-home.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        app: file(relativePath: { eq: "pbj-live/app.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        liveBlock: file(relativePath: { eq: "pbj-live/live-block.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        liveStream: file(relativePath: { eq: "pbj-live/pbj-live-stream.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        pbjSchema: file(relativePath: { eq: "pbj-live/pbj-schema.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        creatively: file(relativePath: { eq: "creatively-footer-cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        spiritune: file(relativePath: { eq: "spiritune-cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        moeAssist: file(relativePath: { eq: "moe-assists-cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        liveApp1: file(relativePath: { eq: "pbj-live/live-app-1.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        liveApp2: file(relativePath: { eq: "pbj-live/live-app-2.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        liveApp3: file(relativePath: { eq: "pbj-live/live-app-3.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        headerImage: file(relativePath: { eq: "pbj-live/pbj-live-app-1.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  )

  const liveAppPreviewImages = [liveApp1, liveApp2, liveApp3]

  return (
    <Layout light>
      <SEO />
      <PortfolioHeader
        heading="A new home for your community"
        singleImage
        image={headerImage}
        containerClassName="pbjLive"
      />
      <div className="portfolio-wrapper">
        <div className="container">
          <section className="portfolio-section-header">
            <div className="portfolio-section-header-text">
              <h4 className="portfolio-section-header-heading">
                Your content - wherever, whenever.
              </h4>
              <p className="portfolio-section-header-description">
                Our branded native apps can integrate directly with exisiting
                streaming services or leverage the pbj.live infrastruture, to
                provide a home for your content and community.
              </p>
            </div>
          </section>
          <section data-sal="slide-up">
            <BackgroundSection
              image={pbjSchema}
              className="creatively__desktop-schema"
            />
          </section>
          <section className="creatively__app">
            <div data-sal="zoom-in">
              <BackgroundSection
                image={liveBlock}
                className="creatively__app-grid --live"
              />
            </div>
            <div data-sal="slide-left">
              <BackgroundSection
                image={app}
                className="creatively__app-grid --app"
              />
            </div>
          </section>
          <section>
            <BackgroundSection
              imageList={liveAppPreviewImages}
              className="creatively_app-preview --live-app"
              isMultiBackground
            />
          </section>
          <section className="portfolio-section-header">
            <div className="portfolio-section-header-text">
              <h4 className="portfolio-section-header-heading --full">
                The right capabilities for all your needs.
              </h4>
              <p className="portfolio-section-header-description">
                Push Notifications, Chat, Q&A, Upcoming and Past shows,
                Ticketing, HealthKit Integrations, and Profile Badges - all
                possible with pbj.live.
              </p>
            </div>
          </section>
          <section data-sal="slide-up">
            <BackgroundSection
              image={liveStream}
              className="creatively__desktop-preview --pbj-live-stream --with-cta"
            >
              <a
                className="preview-cta"
                href="https://pbj.live/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Take me to the Site
              </a>
            </BackgroundSection>
          </section>
          <section data-sal="slide-up">
            <BackgroundSection
              image={desktopHome}
              className="creatively__desktop-preview --pbj-live"
            />
          </section>
          <section className="spiritune-app-navigation-footer">
            <h4 className="spiritune-app-navigation-footer-main-heading">
              See what else we’re building
            </h4>
            <div className="spiritune-app-navigation-footer-grid">
              <Link to="/portfolio/moe-assist">
                <BackgroundSection
                  image={moeAssist}
                  className="spiritune-app-navigation-footer-moe-assist"
                  data-sal="zoom-out"
                >
                  <h4 className="spiritune-app-navigation-footer-heading">
                    Moe Assist
                  </h4>
                  <h4 className="spiritune-app-navigation-footer-description">
                    A management tool for influenecers
                  </h4>
                </BackgroundSection>
              </Link>
              <Link to="/portfolio/spiritune">
                <BackgroundSection
                  image={spiritune}
                  className="spiritune-app-navigation-footer-spiritune"
                >
                  <h4 className="spiritune-app-navigation-footer-heading">
                    Spiritune
                  </h4>
                  <h4 className="spiritune-app-navigation-footer-description">
                    Managing emotional well-being through music
                  </h4>
                </BackgroundSection>
              </Link>
              <Link to="/portfolio/creatively">
                <BackgroundSection
                  image={creatively}
                  className="spiritune-app-navigation-footer-creatively"
                >
                  <h4 className="spiritune-app-navigation-footer-heading">
                    Creatively
                  </h4>
                  <h4 className="spiritune-app-navigation-footer-description">
                    Connecting the creative community
                  </h4>
                </BackgroundSection>
              </Link>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default PbjLive
